import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Dashboard from "../pages/Dashboard.vue";

const routes = [{
    path: "/",
    redirect: "/dashboard/",
  },
  {
    path: "*",
    redirect: "/dashboard/"
  },
  {
    path: "/dashboard",
    component: Dashboard
  },
  {
    path: "/login",
    component: () => import("../pages/Login.vue"),
  },
  {
    path: "/pengguna",
    component: () => import("../pages/users/List.vue"),
  },
  {
    path: "/pengguna/create",
    component: () => import("../pages/users/Form.vue"),
  },
  {
    path: "/pengguna/update/:id",
    component: () => import("../pages/users/Edit.vue"),
  },

  {
    path: "/profil",
    component: () => import("../pages/profil/Profil.vue"),
  },
  {
    path: "/profil/:page",
    component: () => import("../pages/profil/Profil.vue"),
  },

  {
    path: "/pasien",
    component: () => import("../pages/pasien/List.vue"),
  },
  {
    path: "/pasien/create",
    component: () => import("../pages/pasien/Form.vue"),
  },
  {
    path: "/pasien/update/:id",
    component: () => import("../pages/pasien/Form.vue"),
  },
  {
    path: "/pasien/detail/:id",
    component: () => import("../pages/pasien/Detail.vue"),
  },
  {
    path: "/register",
    component: () => import("../pages/register/Index.vue"),
  },
  {
    path: "/register/create",
    component: () => import("../pages/register/Form.vue"),
  },
  {
    path: "/register/update/:id",
    component: () => import("../pages/register/Form.vue"),
  },
  {
    path: "/register/detail/:id",
    component: () => import("../pages/register/Detail.vue"),
  },

  {
    path: "/laporan-tahunan",
    component: () => import("../pages/dashboard/Tahunan.vue"),
  },
  {
    path: "/laporan-bulanan",
    component: () => import("../pages/dashboard/Bulanan.vue"),
  },
  {
    path: "/laporan-mingguan",
    component: () => import("../pages/dashboard/Mingguan.vue"),
  },

  {
    path: "/laporan-tahunan/formulir-01",
    component: () => import("../pages/laporan/form-01/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-02",
    component: () => import("../pages/laporan/form-03/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-03",
    component: () => import("../pages/laporan/form-03/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-04",
    component: () => import("../pages/laporan/form-05/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-05",
    component: () => import("../pages/laporan/form-05/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-06",
    component: () => import("../pages/laporan/form-03/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-07",
    component: () => import("../pages/laporan/form-05/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-08",
    component: () => import("../pages/laporan/form-03/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-09",
    component: () => import("../pages/laporan/form-05/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-10",
    component: () => import("../pages/laporan/form-19/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-11",
    component: () => import("../pages/laporan/form-03/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-12",
    component: () => import("../pages/laporan/form-03/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-13",
    component: () => import("../pages/laporan/form-03/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-14",
    component: () => import("../pages/laporan/form-05/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-15",
    component: () => import("../pages/laporan/form-15/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-16",
    component: () => import("../pages/laporan/form-16/Index.vue"),
  },
  {
    path: "/laporan-mingguan/formulir-17",
    component: () => import("../pages/laporan/form-19/Index.vue"),
  },
  {
    path: "/laporan-mingguan/formulir-18",
    component: () => import("../pages/laporan/form-19/Index.vue"),
  },
  {
    path: "/laporan-bulanan/formulir-19",
    component: () => import("../pages/laporan/form-19/Index.vue"),
  },
  {
    path: "/laporan-tahunan/formulir-20",
    component: () => import("../pages/laporan/form-19/Index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("sipData");
  const userAceess = JSON.parse(isLoggedIn);

  const userLevel = userAceess ? userAceess.level : null;
  const requiredLevel = to.meta.requiredLevel;

  if (to.path !== "/login" && !isLoggedIn && to.path !== "/registrasi") {
    next("/login");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/dashboard");
  } else if (requiredLevel && userLevel && userLevel !== requiredLevel) {
    if (!requiredLevel.includes(userLevel)) {
      next("/forbidden"); // Tambahkan halaman forbidden jika level pengguna tidak sesuai
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;