<script>
const sidebarMenu = [
  { path: "/dashboard", icon: "fa fa-th-large f-s-20", title: "Dashboard" },
  {
    path: "/laporan-tahunan",
    icon: "fa fa-calendar-alt f-s-20",
    title: "Laporan Tahunan",
  },
  {
    path: "/laporan-bulanan",
    icon: "fa fa-calendar f-s-20",
    title: "Laporan Bulanan",
  },
  {
    path: "/laporan-mingguan",
    icon: "fa fa-calendar-minus f-s-20",
    title: "Laporan Mingguan",
  },
  // {
  //   path: "/register",
  //   icon: "fa fa-file f-s-20",
  //   title: "e-Register",
  // },
  {
    path: "/pasien",
    icon: "fa fa-users f-s-20",
    title: "Pasien",
  },
  {
    path: "/pengguna",
    icon: "fa fa-users f-s-20",
    title: "Pengguna",
  },
];

export default sidebarMenu;
</script>
