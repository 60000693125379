<template>
  <div id="footer" class="footer">
    &copy; 2023 SIP (Sistem Informasi Puskesmas) - All Rights Reserved
  </div>
</template>

<script>
import PageOptions from "../../config/PageOptions.vue";

export default {
  name: "FooterPage",
  data() {
    return {
      pageOptions: PageOptions,
    };
  },
};
</script>
