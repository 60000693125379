<template>
  <div>
    <h1 class="page-header">Dashboard <small></small></h1>

    <!-- <div class="row">
      <iframe
        src="https://sigiziterpadu.kemkes.go.id/login_sisfo/"
        frameborder="0"
        width="100%"
        height="700px"
      ></iframe>
      <a
        href="https://sigiziterpadu.kemkes.go.id/login_sisfo/"
        class="btn btn-primary"
        target="_blank"
        >Login Sigizi Terpadu</a
      >
    </div> -->
    <!-- <div class="row">
      <div class="col-xl-6 col-md-6">
        <div class="widget widget-stats bg-teal">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-users fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">PENDUKUNG</div>
            <div class="stats-number">{{ this.pendukungTotal }}</div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-md-6">
        <div class="widget widget-stats bg-blue">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-ticket-alt fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">TIKET</div>
            <div class="stats-number">{{ this.tiketTotal }}</div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-md-6">
        <div ref="barChart"></div>
      </div>
      <div class="col-md-6">
        <div ref="pieChart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import sipData from "../plugins/sipData";
export default {
  name: "DashboardPage",
  mixins: [sipData],
  data() {
    return {
      nama_kabupaten: [],
      jumlah_pendukung: [],
      pendukungTotal: 0,
      tiketTotal: 0,
    };
  },
  created() {
    console.log(this.userAccess);
  },
};
</script>